<div class="summary theme-new-green">
    <div id="page-header" class="banner-area-1">
        <h1 class="bh-sl-title">Event Details</h1>
        <div class="row subheader-area-1">
            <div class="form-group main-title-info">
                <span class="icon-location-green"></span><a [href]="mapUrl" target="_blank">{{summary?.location?.sales_location}}</a> 
                <ng-template #copyTooltipTemplate>{{ tooltipText }}</ng-template>
                <button #btnCopy class="btn btn-outline-primary ml-3" (click)="copyToClipboard(btnCopy)"  [tooltip]="copyTooltipTemplate"  type="button"><i class="fa fa-copy"></i></button>
            </div>
            <div class="form-group main-title-info">
                <span class="icon-eye-green"></span> Visitors {{ NoOfViews }}
            </div>
            <div class="form-group main-title-info-sm">
                <span class="icon-calander-green"></span> {{summary?.schedule?.event_start_datetime | momentDate: 'fullDate':summary?.schedule?.location_timezoneId}}
                {{summary?.schedule?.event_start_datetime | momentDate:'shortTime':summary?.schedule?.location_timezoneId}} - {{summary?.schedule?.event_end_datetime |
                    momentDate:'shortTime':summary?.schedule?.location_timezoneId}}
            </div>
        </div>
    </div>
    
    <div class="row pt-3">
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-3"><strong>When: </strong></div>
                <div class="col-sm-3">Date</div>
                <div class="col-sm-6">{{summary?.schedule?.event_start_datetime | momentDate: 'fullDate':summary?.schedule?.location_timezoneId}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">Start Time</div>
                <div class="col-sm-6">{{summary?.schedule?.event_start_datetime | momentDate:'shortTime':summary?.schedule?.location_timezoneId}}</div>
            </div>
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">End Time</div>
                <div class="col-sm-6">{{summary?.schedule?.event_end_datetime | momentDate:'shortTime':summary?.schedule?.location_timezoneId}}</div>
            </div>
        </div>
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-2"><strong>Where: </strong></div>
                <div class="col-sm-3">Address</div>
                <div class="col-sm-7"><a [href]="mapUrl" target="_blank">{{summary?.location.start_streetno}}
                    {{summary?.location?.end_streetno == '' ? ' ' : ' - ' }}
                    {{summary?.location?.end_streetno}} {{summary?.location?.street_name}}</a> 
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-3">City</div>
                <div class="col-sm-5">{{summary?.location.city}}</div>
            </div>
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-3">Province/State</div>
                <div class="col-sm-5">{{summary?.location.prov_state}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-3"><strong>Type of Sale:</strong></div>
                <div class="col-sm-8">{{summary?.metaData?.kindOfSale}}</div>
            </div>
            <!-- <strong>Type of Sale:</strong> {{summary?.metaData?.kindOfSale}} -->
        </div>
        <div class="form-group col-sm-6">
            <div class="row">
                <div class="col-sm-2"><strong>Notes:</strong></div>
                <div class="col-sm-10">{{summary?.schedule?.notes}}</div>
            </div>
            <!-- <strong>Notes: </strong> {{summary?.schedule?.notes}} -->
        </div>
    </div>
    <div>
        <button type="button" class="btn-link btn-lg"
                      (click)="viewSurvey(thesurvey)" *ngIf="!ownevent">Leave feedback for the host</button>
        <button type="button" class="btn-link btn-lg"
                    (click)="viewSurveyResult(thesurveyresult)" *ngIf="ownevent">Feedback result</button>
    </div>
    <div class="product-filter-tabs">
        <div class="each-filter-tab">
            <label class="{{filterValue === 'all' ? 'active': ''}}" (click)="onFilterTabChange('all')">All Products</label>
            <label class="{{filterValue === 'sale' ? 'active': ''}}" (click)="onFilterTabChange('sale')">Listed Items</label>
            <label class="{{filterValue === 'giveaway' ? 'active': ''}}" (click)="onFilterTabChange('giveaway')">Giveaway Items</label>
        </div>
    </div>
    <div class="product-list">
        <app-product-list [productList]="summary?.eventItems | KeyFilter: 'is_for_giveaway':itemKind" readOnly="true" >
        </app-product-list>
    </div>
    <!-- <div class="product-details-more">
        <a>view more</a>
    </div> -->
</div>

<ng-template #thesurveyresult>
    <div class="modal-body text-center">
        <h3>Respondents to my Event</h3>
        <form name="form">
            <div class="modal-body">
                <table id="surveytable" class="table table-bordred">
                    <thead>
                        <th class="text-center">Responders</th>
                        <th class="text-center">Number</th>
                    </thead>
                    <tbody class="text-center">
                        <tr>
                            <td>People who are visiting</td>
                            <td>{{surveyStat.visit}}</td>
                        </tr>
                        <tr>
                            <td>People who are interested in items</td>
                            <td>{{surveyStat.interested}}</td>
                        </tr>
                        <tr>
                            <td>People who might buy items</td>
                            <td>{{surveyStat.visit_and_buy}}</td>
                        </tr>
                        <tr>
                            <td>People interested in the give away</td>
                            <td>{{surveyStat.take_give_aways}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="decline()">Close</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #thesurvey>
    <div class="modal-body text-center">
        <h3>Kindly Review (If possible)</h3>
        <form name="form">
            <div class="modal-header">
              <h4 class="modal-title pull-left">Event Visitor Survey</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-left">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="survey_interested" [(ngModel)]="survey.visit">
                    <label class="form-check-label" for="survey_interested">
                        <h5>I plan on visiting</h5>
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="survey_visit_and_buy" [(ngModel)]="survey.interested">
                    <label class="form-check-label" for="survey_visit_and_buy">
                        <h5>I have seen somethings I might be interested in</h5>
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="survey_take_give_aways" [(ngModel)]="survey.visit_and_buy">
                    <label class="form-check-label" for="survey_take_give_aways">
                        <h5>I will be visiting the event and possibly buying an item or two</h5>
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="survey_take_give_aways" [(ngModel)]="survey.take_give_aways">
                    <label class="form-check-label" for="survey_take_give_aways">
                        <h5>I am interested in the give aways</h5>
                    </label>
                </div>
                <div class="form-group">
                    <input type="text" name="survey_name" placeholder="Name (Optional)"
                                [(ngModel)]="survey.name" class="form-control" />
                </div>
                <div class="form-group">
                    <input type="text" name="survey_email" placeholder="Email (Optional)"
                        [(ngModel)]="survey.email" class="form-control" />
                </div>
                <div class="form-group">
                    <textarea  type="text" name="survey_notes" placeholder="Notes (Optional)"
                        [(ngModel)]="survey.notes" class="form-control" ></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="submitSurvey()" >Submit</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="decline()">Close</button>
            </div>
        </form>
    </div>
</ng-template>
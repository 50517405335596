<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Yard, Garage, Moving Sale Marketing - Subscription Plans</title>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">
    <style>
        .header2Pt {
            background-color: rgb(240, 244, 244);
            color: black;
            border: 2px solid black;
            margin: 20px;
            padding: 20px;
        }

        .header2PtA {
            background-color: rgb(179, 204, 238);
            color: black;
            border: 2px solid black;
            margin: 20px;
            padding: 20px;
        }

        .header3ptB {
            background-color: rgb(179, 204, 238);
            color: black;
            border: 4px solid black;
            margin: 20px;
            padding: 20px;

        }

        .header4ptword {
            background-color: white;
            color: black;
            border: 4px solid black;
            margin: 15px;
            padding: 15px;
        }
    </style>

    <style>
        body {
            font-family: 'Roboto', sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f8f9fa;
            color: #333;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
        }

        header {
            text-align: center;
            padding: 30px 0;
        }

        header h1 {
            font-size: 2.5rem;
            margin: 0;
        }

        header p {
            font-size: 1.2rem;
            color: #666;
        }

        .plans {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
        }

        .plan {
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            width: 300px;
            padding: 20px;
            text-align: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s, box-shadow 0.3s;
        }

        .plan:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        .plan h3 {
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        .plan .price {
            font-size: 2rem;
            color: #28a745;
            margin: 15px 0;
        }

        .plan ul {
            list-style: none;
            padding: 0;
            margin: 20px 0;
        }

        .plan ul li {
            font-size: 1rem;
            padding: 8px 0;
            border-bottom: 1px solid #eee;
        }

        .plan ul li:last-child {
            border-bottom: none;
        }

        .plan button {
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 10px 20px;
            font-size: 1rem;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
        }

        .plan button:hover {
            background-color: #0056b3;
        }

        footer {
            text-align: center;
            padding: 20px 0;
            margin-top: 40px;
            background-color: #343a40;
            color: #fff;
        }

        footer a {
            color: #17a2b8;
            text-decoration: none;
        }

        footer a:hover {
            text-decoration: underline;
        }
    </style>
</head>
<!-- <form name="form" (ngSubmit)="sendSubscriptionTierReqeust()" #f="ngForm" novalidate> -->

<body>
    <div class="container">
        <header>
            <h1>Advertise Your Yard, Garage, Moving or Liquidation Sale</h1>
            <p>Choose the perfect plan to boost your visibility and attract more visitors to your sale event!</p>
        </header>
        <section class="plans">
            <!-- Basic Plan -->
            <div class="plan">
                <h3>Basic Free 7 Day Package</h3>
                <p class="price">Free, Free</p>
                <ul>
                    <li>1 Featured Event Sale</li>
                    <li>Visible for 7 Days</li>
                    <li>Basic Ad Placement</li>
                </ul>
                <button type="button" tooltip="Subscribe Now" class="btn button button-white btn-primary mt-3"
                    [routerLink]="['/register']">Subscribe</button>
                <!--Send Email for plan-->
            </div>
            <!-- Basic Plan -->
            <div class="plan">
                <h3>Standard 14 Day Package</h3>
                <p class="price">$3.99 US, $5.99 CDN</p>
                <ul>
                    <li>Unlimited Featured Event Sales</li>
                    <li>Visible for 14 Days</li>
                    <li>Priority Ad Placement</li>
                    <!-- <li>Social Media Promotion</li> -->
                </ul>
                <button type="button" tooltip="Subscribe Now" class="btn button button-white btn-primary mt-3"
                    [routerLink]="['/register']">Subscribe</button>
            </div>
            <!-- Premium Plan -->
            <div class="plan">
                <h3>Premium 30 Day Plan</h3>
                <p class="price">$6.99 US, $10.49 CDN</p>
                <ul>
                    <li>Unlimited Featured Event Sales</li>
                    <li>Visible for 30 Days</li>
                    <li>Top Ad Placement</li>
                    <li>Dedicated Support</li>
                    <li>Social Media Promotion</li>
                </ul>
                <button type="button" tooltip="Subscribe Now" class="btn button button-white btn-primary mt-3"
                    [routerLink]="['/register']">Subscribe</button>
            </div>
        </section>
    </div>
    <footer>
        <div>
            <a href="https://yardgaragemovingsales.com">Rechoz Ventures Inc.</a> &copy; 2025.
        </div>
        <!-- <a href="#">Privacy Policy</a> |  -->
        <a [routerLink]="['/privacy']" class="btn btn-link px-0 search-btn" target="_blank"> Privacy Policy</a>
        <!-- <a href="#">Copy right/a></p> -->
        <a [routerLink]="['/copyright-ownership']" class="btn btn-link px-0 search-btn" target="_blank"> Copy
            Right</a>
        <!-- <a href="#">Terms of Service</a></p> -->
    </footer>
</body>
<!-- </form> -->

</html>
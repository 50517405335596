<footer class="app-footer">
  <div class="container-fluid">
    <div class="row">
      <div>
        <a href="https://yardgaragemovingsales.com">Rechoz Ventures Inc.</a> &copy; 2025.
      </div>
      <div class="col-sm" style="margin: 0 auto;max-width: 80%;">
        <div class="navbar navbar-expand-lg">
          <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent"> -->
          <ul class="navbar-nav m-auto">
            <li class="nav-item"><a class="nav-link" [routerLink]="['/subscription-tier']">Register</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/contact-us']">Contact Us</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/about-us']">About Us</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/copyright-ownership']">Copyright</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/privacy']">Privacy</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/benefits-use']">Benefits and Use</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/organize']">Organize</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/review-freewill-pay']">Reviews</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/help-assist-set-up']">Help Set Up</a></li>
            <li class="nav-item"><a class="nav-link" href="https://youtu.be/1vwtR5PEKfE" target="_blank">How to Use</a>

            </li>
          </ul>
          <!-- </div> -->
        </div>
      </div>
      <!-- <div>
        <span class="float-right">Powered by <a href="https://yardgaragemovingsales.com">Rechoz Ventures Inc.</a></span>
      </div> -->
    </div>
  </div>
  <!-- <div class="container-fluid">
    <div class="row">
      <div>
        <a href="http://yagasales.com">Yaga Sales</a> &copy; 2019.
      </div>
      <div class="col-sm navbar">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item"><a class="nav-link" href="http://yagasales.com">Contact Us</a></li>
          <li class="nav-item"><a class="nav-link" href="http://yagasales.com">About Us</a></li>
          <li class="nav-item"><a class="nav-link" href="http://yagasales.com">Copy Rights and Legal terms</a></li>
          <li class="nav-item"><a class="nav-link" href="http://yagasales.com">Privacy</a></li>
        </ul>
       <div class="nav navbar-nav" style="margin: 0 auto;">
          <span class="mr-3 nav-item"><a class="nav-link" href="http://yagasales.com">Contact Us</a></span>
          <span class="mr-3 nav-item"><a class="nav-link" href="http://yagasales.com">About Us</a></span>
          <span class="mr-3 nav-item"><a class="nav-link" href="http://yagasales.com">Copy Rights and Legal terms</a></span>
          <span class="mr-3 nav-item"><a class="nav-link" href="http://yagasales.com">Privacy</a></span>
        </div>
      </div>
      <div>
        <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
      </div>
    </div>
  </div> -->
  <!-- <div>
    <a href="http://yagasales.com">Yaga Sales</a> &copy; 2019.
    <div>
      <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
      <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
      <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
      <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
    </div>
    <span class="float-right">Powered by <a href="http://yagasales.com">Yaga Sales</a></span>
    
  </div> -->
</footer>
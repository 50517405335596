<style>
    .header2Pt {
        background-color: rgb(153, 244, 241);
        color: black;
        border: 2px solid black;
        margin: 20px;
        padding: 20px;
    }

    .header2PtA {
        background-color: rgb(179, 204, 238);
        color: black;
        border: 2px solid black;
        margin: 20px;
        padding: 20px;
    }
</style>
<div class="animated fadeIn infoBox col-8 mx-auto">
    <div class="row">
        <div class="col-lg-12 customLG">
            <div class="card">
                <div class="card-header">
                    <!-- <i class="fa fa-info"></i> -->
                    <header id="Heading1">
                        <h1>STEP BY STEP GUIDE ON HOW TO ORGANIZE A YARD, GARAGE OR MOVING SALE </h1>
                    </header>
                </div>
                <div class="card-body text-justify">
                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Pre-Planning:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>1. Check Local Regulations:</h4>
                        </header>

                        <p>
                            • Before you start, check with your local municipality or homeowners’ association to ensure
                            you're allowed to have a yard sale and if there are any regulations you need to follow.
                        </p>

                        <header id="Heading4">
                            <h4>2. Select a Date and Time:</h4>
                        </header>
                        <p>
                            • Choose a date and time that is convenient for both you and potential buyers. Weekends are
                            often popular for yard sales.
                        </p>
                        <header id="Heading4">
                            <h4>3. Gather Supplies:</h4>
                        </header>
                        <p>
                            • Collect tables, racks, or other display items for your items.
                        </p>
                        <p>
                            • Have price stickers, markers, and signs ready.
                        </p>
                        <p>
                            • Get cash for making change.
                        </p>
                    </div>
                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Sorting and Pricing:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">

                        <header id="Heading4">
                            <h4>4. Declutter and Organize:</h4>
                        </header>
                        <p>
                            • Go through your belongings and decide what you want to sell. Separate items into
                            categories.
                        </p>

                        <header id="Heading4">
                            <h4>5. Price Your Items:</h4>
                        </header>
                        <p>
                            • Price items reasonably. Consider the condition, age, and original cost.
                        </p>
                        <p>
                            • Group similar items and use general price ranges (e.g., $1, $5, $10).
                        </p>
                        <p>
                            • Be prepared to negotiate, especially towards the end of the sale.
                        </p>
                    </div>


                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Promotion and Advertising:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>6. Advertise:</h4>
                        </header>
                        <p>
                            • Use local online platforms, community bulletin boards, and social media to advertise your
                            yard
                            sale.
                        </p>
                        <p>
                            • Create eye-catching signs with the date, time, and address. Place them strategically
                            around
                            your neighborhood.
                        </p>
                        <p>
                            • Highlight valuable items: If you have particularly valuable or desirable items, feature
                            them
                            prominently in your advertising to attract more buyers.
                        </p>
                        <p>
                            <a [routerLink]='"."' [fragment]='"EasySteps"'>Click here to start promoting your sale
                                event</a>
                        </p>
                    </div>


                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Set Up and Preparation:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>7. Start Early:</h4>
                        </header>
                        <p>
                            • Set up early: Start setting up your sale area well before the designated start time.
                        </p>
                        <p>
                            • Ensure everything is clean: Clean items you're selling to make them more appealing to
                            buyers.
                        </p>
                        <header id="Heading4">
                            <h4>8. Organize the Layout:</h4>
                        </header>
                        <p>
                            • Arrange your items in an organized and appealing manner. Group similar items together.
                        </p>
                        <p>
                            • Place more desirable or larger items near the entrance to attract attention.
                        </p>
                        <p>
                            • Display items attractively: Arrange items neatly on tables and use racks if you have
                            clothing
                            to sell.
                        </p>

                        <header id="Heading4">
                            <h4>9. Create a Checkout Area:</h4>
                        </header>
                        <p>

                            • Designate a central area for handling transactions.
                        </p>
                        <p>
                            • Get plenty of small bills and coins to provide change for your customers.
                        </p>
                        <p>
                            • Have a small table with a cash box, bags, and change.
                        </p>
                    </div>

                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>During the Yard Sale:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>9. Be Available:</h4>
                        </header>
                        <p>
                            • Be present to answer questions, negotiate prices, and assist customers.
                        </p>
                        <header id="Heading4">
                            <h4>10. Consider Bundle Deals:</h4>
                        </header>
                        <p>
                            • Encourage sales by offering deals for buying multiple items or bundling related items
                            together.
                        </p>

                        <header id="Heading4">
                            <h4>11. Stay Safe:</h4>
                        </header>
                        <p>
                            • Have someone else with you during the sale for security.
                        </p>
                        <p>
                            • Keep your home secure, especially if you need to step away.
                        </p>
                    </div>

                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>Closing:</h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>12. Discounts Towards the End:</h4>
                        </header>
                        <p>
                            • Consider reducing prices on items that haven’t sold as the day progresses.
                        </p>
                        <p>
                            • Be flexible: Remember that the goal is to lighten your load, so be open to negotiating
                            prices
                            with buyers.

                        </p>
                        <header id="Heading4">
                            <h4>13. Donate or Dispose:</h4>
                        </header>
                        <p>
                            • Decide in advance what you'll do with items that don't sell. You may choose to donate them
                            or
                            plan for disposal.
                        </p>

                        <header id="Heading4">
                            <h4>14. Clean Up:</h4>
                        </header>
                        <p>
                            • Ensure the area is clean after the sale. Properly dispose of any remaining items.
                        </p>

                        <header id="Heading4">
                            <h4>15. Reflect for Next Time:</h4>
                        </header>
                        <p>
                            • Note what worked well and what could be improved for future yard sales.
                        </p>

                    </div>


                    <p>

                    </p>

                    <p>
                        <a [routerLink]='"."' [fragment]='"EasySteps"'>Let us help you organize a memorable event, give
                            it a try by clicking here and following
                            these steps</a>
                    </p>
                    <div class="header2PtA">
                        <header id="Heading2">
                            <h2>What are the benefits of using this Site ? How does our site help? </h2>
                        </header>
                    </div>
                    <div class="header2Pt">
                        <header id="Heading4">
                            <h4>Publicity at no cost</h4>
                        </header>
                        <p>
                            Our site provides you with the ability and opportunity to market and advertise your sale
                            event
                            to all those in your neighbourhood with little effort and no cost.
                            Your sale event and items for sale will be targeted to the customers who want your items.
                            No need for flyers, posters, etc.
                        </p>
                        <header id="Heading4">
                            <h4>Moving sale helper</h4>
                        </header>
                        <p>
                            You are moving, and plan to get rid of some of your belongings,
                            why not schedule a moving sale and use this site to help others who might be interested in
                            some
                            of your items.
                            Use our site to publicize your event within a short period of time.
                        </p>
                        <header id="Heading4">
                            <h4>Avoid single / double item listings repetition</h4>
                        </header>
                        <p>
                            Avoid the hassle of selling single items at different dates and
                            times by having a one or two event sale.
                            This site enables you to plan and use your time more effectively. Invite all prospective
                            buyers
                            to your sale
                            event.
                        </p>
                        <header id="Heading4">
                            <h4>Scheduling and timing</h4>
                        </header>
                        <p>
                            Searching our site, you could plan and schedule a sales event around the same period
                            as other scheduled sales events in your neighbourhood
                            exposing your event to a larger audience. You eliminate the guess work.
                        </p>

                        <header id="Heading4">
                            <h4>Decluttering and then what?</h4>
                        </header>
                        <p>
                            As you declutter, take away the headache of disposal and make some cash while the
                            items you no longer need are
                            picked up by those who find a need for them. With our site, you can specify which items are
                            free
                            and which are
                            not.
                        </p>

                        <header id="Heading4">
                            <h4>Time savings</h4>
                        </header>
                        <p>
                            Potential buyers can view your items and provide some feedback ahead of your sale event.
                        </p>
                        <header id="Heading4">
                            <h4>Environmentally friendly</h4>
                        </header>
                        <p>
                            Finally, be a friend to the environment by keeping items away from the landfill a bit
                            longer by encouraging “REUSE”
                        </p>

                        <header id="Heading4">
                            <h4 id="EasySteps"> Go through these 5 easy steps from registration to broadcast.</h4>
                        </header>
                    </div>

                    <p>
                        1. Subscribe; enrol by clicking on the Subscribe button and creating an account using your email
                        address as your
                        user
                        name.
                        <button type="button" tooltip="Click here to Subscribe"
                            class="btn button button-black btn-primary mt-3" [routerLink]="['/subscription-tier']">Click here to
                            Subscribe</button>
                    </p>
                    <p>
                        2. Create a yard, garage or moving sale event; simply provide the location (street
                        intersection), city and date
                        of the sale event
                        event.
                    </p>
                    <p>
                        3. Enter a list or category of items you have on sale and upload pictures if possible.
                    </p>
                    <p>
                        4. Broadcast the event.
                    </p>
                    <p>
                        5. After a review and approval process, your event is broadcasted through an extensive network
                        of marketing
                        outlets.
                    </p>
                    <p>
                        To search for sale events.
                    </p>
                    <p>
                        <button type="button" tooltip="Click here to Search for a Sale Event"
                            class="btn button button-black btn-primary mt-3" [routerLink]="['/search-sale']">Click here
                            to
                            Search</button>
                    </p>
                    <p>
                        Try it today!!!!.
                    </p>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-tier',
  templateUrl: './subscription-tier.component.html',
  styleUrls: ['./subscription-tier.component.scss']
})
export class SubscriptionTierComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

  // sendSubscriptionTierReqeust() {
  //     this.loading = true;
  //     this.userService.sendSubscriptionTierReqeust(this.model)
  //       .subscribe(
  //         data => {
  //           this.loading = false;
  //           this.alertService.success(data.message, true);
  //           this.router.navigate(['/home']);
  //         },
  //         (error: HttpErrorResponse) => {
  //           console.log(`Error: ${error}`);
  //           this.loading = false;
  //         }
  //       );
  //   }



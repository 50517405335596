import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventService } from '.';

@Injectable({
    providedIn: 'root'
})
export class PageViewService {
    private pageViews = new Subject<number>();
    constructor(private router: Router
        , private route: ActivatedRoute
        , private eventService: EventService) {

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            // now query the activated route
            map(() => this.rootRoute(this.route)),
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
        ).subscribe((route: ActivatedRoute) => {
            this.logPageView(route.snapshot.params.eventIndentifier);
        });
    }

    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }


    logPageView(eventId: string) {
        console.log(`Page view: ${eventId}`);        
        this.eventService.updateViewCount(eventId)
            .subscribe(views => {
                this.pageViews.next(views);
            });
    }

    getViewCount(): Observable<number> {
        return this.pageViews.asObservable();
    }
}
<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group mb-0">
          <div class="card p-4">
            <div class="card-body loginBox">
              <h1>Sign in</h1>
              <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" class="loginForm" novalidate>
                <p class="text-muted">Sign In to your account</p>
                <div class="input-group" [ngClass]="{ 'has-error': f.submitted && !username.valid}">
                  <span class="input-group-addon"><i class="icon-user"></i></span>
                  <input type="text" class="form-control" placeholder="Username or Email Address" name="username"
                    [(ngModel)]="model.username" #username="ngModel" required />
                </div>
                <div *ngIf="f.submitted && !username.valid" class="has-error"><span class="help-block">Username is
                    required</span></div>
                <div class="input-group mt-3" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                  <span class="input-group-addon"><i class="icon-lock"></i></span>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                    [(ngModel)]="model.password" #password="ngModel" required />

                </div>
                <div *ngIf="f.submitted && !password.valid" class="has-error"><span class="help-block">Password is
                    required</span> </div>
                <div class="mt-3 loginbuttonwrap">
                  <a [routerLink]="['/forgotpassword']" tooltip="Click here to reset your password through your email"
                    class="btn btn-link px-0 forgotPass">Forgot Password ? Click Here </a>
                  <button [disabled]="loading" class="btn btn-primary px-4">Login</button>
                  <p></p>
                  <a [routerLink]="['/search-sale']" class="btn btn-link px-0 search-btn" target="_blank">Search for
                    Yard, Garage and
                    Moving Sales</a>
                  <p></p>
                  <a [routerLink]="['/help-assist-set-up']" class="btn btn-link px-0 search-btn" target="_blank"> Need
                    help setting up your Event Sale? Click here</a>

                  <img *ngIf="loading"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </form>
            </div>
          </div>
          <div class="card text-white bg-primary py-5" style="width:100%">
            <!--hidden-md-down-->
            <div class="card-body text-center">
              <div>
                <img style="width: 100%" src="../assets/img/logo-transparent.png">
              </div>
              <div>
                <h6>Subscribe to Advertise your sale.</h6>
                <button type="button" tooltip="Create your account" class="btn button button-white btn-primary mt-3"
                [routerLink]="['/subscription-tier']">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Temporarily disable ad banner>-->
    <!--<app-ad-banner></app-ad-banner>-->




  </div>
</div>
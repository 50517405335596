import { Injectable } from '@angular/core';
// import { Http, Headers, RequestOptions, Response } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import {
  User,
  CurrentUser,
  FeedbackError,
  ContactDto,
  AssistSetUpDto,
  EFarmingNonRegisterData,
  PriceTierSelectionDto
} from '../_models/index';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  // getById(id: number) {
  //     return this.http.get('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
  // }

  registerUser(user: User) {
    return this.http
      .post('/user/register', user);
      // .map((response: Response) => response.json());
  }

  getUserProfile(userId: string): Observable<User> {
    return this.http
      .get<User>(`/user/profile/${userId}`);
      // .map(response => <User>response.json());
  }

  updateUserProfile(user: User) {
    return this.http
      .post('/user/profile', user);
      // .map((response: Response) => response.json());
  }

  resetPasswordRequest(userName: string, clientBaseUrl: string) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post<any>(`/user/reset/${userName}`, JSON.stringify(clientBaseUrl));
      // .map((response: Response) => response.json());
  }

  resetPassword(user: User) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post<any>('/user/reset', user);
      // .map((response: Response) => response.json());
  }

  changePassword(user: User) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post<any>('/user/changePassword', user);
  }

  getUserDetails(userName: string): Observable<User> {
      return this.http
          .get<User>(`/user/${userName}`);
      // .map(response => <Customer>response.json());
    // .subscribe((customer: Customer) => customer);
  }

  saveUserProfile(user: User) {
    return this.http
      .put(`/user`, user);
      // .map((response: Response) => response.json());
  }

  sendError(error: FeedbackError) {
    return this.http
      .post<any>(`/user/reporterror`, error);
      // .map((response: Response) => response.json());
  }

  sendContactUsRequest(contact: ContactDto){
    return this.http.post<any>('/user/contact-us', contact);
  }

  sendSubscriptionTierReqeust(subscriptionTier: PriceTierSelectionDto){
    return this.http.post<any>('/user/subscription-tier', subscriptionTier);
  }

  sendAssistSetUpReqeust(assistsetup: AssistSetUpDto){
    return this.http.post<any>('/user/help-assist-set-up', assistsetup);
  }

  eFarmingUserSubscribe(eFarmingNonRegisterUser: EFarmingNonRegisterData){
    return this.http.post<any>('/user/efarming-subscribe', eFarmingNonRegisterUser);
  }

  get sessionUser(): CurrentUser {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // tslint:disable-next-line:curly
    if (user) return user;
    return null;
  }
}

export class CurrentUser {
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  user_type: UserType;
  registration_no: string;
  customer_id: number;
  token: string;
  refresh_token: string;
}

export class User1 {
  constructor() {
    this.customer = new Customer1();
    this.customer.primary_address = new Address1();
    this.tutor = new Tutor();
  }
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  gender: string;
  reset_token: string;
  primary_address: Address1;
  user_type: string;
  customer_ids: number[];
  customer: Customer1;
  tutor: Tutor;
}

export class Address1 {
  address1: string;
  address2: string;
  city: string;
  prov_state: string;
  country: string;
  postal_code: string;
}
export class Attendee {
  id: number;
  first_name: string;
  last_name: string;
  age: number;
}

export class Customer1 {
  id?: number;
  auth_id: string;
  first_name: string;
  last_name: string;
  primary_address?: Address1;
  is_attendee: boolean;
  is_agreement_accepted?: boolean;
  mobile_no: string;
  gender: string;
  registration_no?: string;
  attendees?: Attendee[];
  age: number;
  referral_type: string;
  referral_info?: string;
}

export class Tutor {
  id?: number;
  auth_id: string;
  first_name: string;
  last_name: string;
  primary_address?: Address;
  registration_no?: string;
  mobile_no: string;
  gender: string;
  is_agreement_accepted?: boolean;
}

// export class CustomerCourse {
//   id: number;
//   course_id: number;
//   course_identifier: string;
//   promo_code: string;
//   webinar_detail: string;
// }

export class CustomerUser {
  constructor() {
    this.authUser = new User1();
    this.customer = new Customer1();
    this.customer.primary_address = new Address1();
  }
  authUser: User1;
  customer: Customer1;
}

export enum UserRole {
  CustomerRole = 'Customer',
  TutorRole = 'Tutor',
  AdminRole = 'Admin'
}

export class ExchangeRefreshToken {
  access_token: string;
  refresh_token: string;
}



export interface User {
  email: string;
  password: string;
  reset_token: string;
  /*password_hash: string;
  password_salt: string;
  reset_token: string;*/
  user_type: UserType;
  user_details: UserDetail;
  kindOfSale: string;
  opt1RegEmailLetters: boolean;
  opt2NotifyOfSale: boolean;
  /*refresh_token: string;
  request_ip: string;
  last_login_on: Date | string | null;

  inserted_date: Date | string;
  updated_date: Date | string | null;
  inserted_by: string;
  updated_by: string;
  _version: number | null;*/
}

export interface UserDetail {
  customer: Customer;
  admin: Admin;
}

export interface Admin {
  first_name: string;
  last_name: string;
  tel_no: string;
  fax_no: string;
  gender: string;
  reg_no: string;
}

export interface Customer {
  first_name: string;
  last_name: string;
  tel_no: string;
  fax_no: string;
  gender: string;
  reg_no: string;
  // initial: string;
  primary_address: Address;
  billing_address: Address;
  is_billing_address_same: boolean;
  is_agreement_accepted: boolean;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  prov_state: string;
  country: string;
  postal_code: string;
}

export interface FeedbackError {
  subject: string;
  message: string;
  statuscode: string,
  feedback: string
}

export enum UserType {
  Admin = 'admin',
  Customer = 'customer'
}

export interface ContactDto {
  firstName: string;
  lastName: string;
  email: string;
  comments: string;
}

export interface AssistSetUpDto {
  firstName: string;
  phoneNumber: string;
  email: string;
  address: string;
  address1: string;
  city: string;
  stateprov: string;
  code: string;
  country: string;
  date: string;
  time: string;
  comments: string;
}

export interface EFarmingNonRegisterData {
  email: string;
  contact_name: string;
  city: string;
  prov_state: string;
  country: string;
  postal_code: string;
  type_of_sale_interest: string;
  opt_email_letters: boolean;
  opt_notify_sale: boolean;
  opt_want_info: boolean;
  datetime_entered: Date;
}

export interface PriceTierSelectionDto
{
  priceSubscriptionName: string;
  amount: string;
  numDays: string;
}
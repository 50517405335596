<div class="bh-sl-container theme-new-green">
  <div id="page-header" class="banner-area-1">
    <h1 class="bh-sl-title">Search for Sale Event</h1>
  </div>

  <div class="bh-sl-form-container">
    <form name="form" (ngSubmit)="f.form.valid && search()" #f="ngForm" novalidate>
      <div class="form-group row">
        <div class="col-md-4">
          <div [ngClass]="{ 'has-error': f.submitted && !f.form.valid }">
            <input placeholder="Enter Street Name" type="text" class="form-control" [(ngModel)]="address" name="place"
              #place required />
            <span class="help-block">Enter Street Name to search for Yard, Garage and Moving Sales</span>
          </div>
          <div *ngIf="f.submitted && !f.form.valid" class="has-error"><span class="help-block">Place is
              required</span>
          </div>
        </div>
        <div class="col-md-3 make-flex-row">
          <div class="has-display-msg">
            <em> </em>
            <select class="form-control pdl-50" name="radious" [(ngModel)]="selectedRadius">
              <option *ngFor="let radios of radioses" [ngValue]="radios.value">
                {{radios.text}}</option>
            </select>
            <span class="help-block">Radius in km</span>
          </div>
          <button class="btn btn-primary bh-sl-submit search-btn" tooltip="Click here to Search"><i
              class="fa fa-search"></i></button>
        </div>
        <div class="col-md-5 grid-type-buttons">
          <div class="first-part">
            <label class="btn-grid-icon {{resultViewType === 'GridView' ? 'active' : ''}}"
              (click)="onViewRadioBtnClick('GridView')"></label>
            <label class="btn-list-icon {{resultViewType === 'ListView' ? 'active' : ''}} mg-left-15"
              (click)="onViewRadioBtnClick('ListView')"></label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div id="bh-sl-map-container" class="bh-sl-map-container">
    <div class="bh-sl-loc-list">
      <div class="event-result {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}">
        <div id="bh-sl-map"
          class="bh-sl-map bh-sl-map-open  {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}"
          style="position: relative; overflow: hidden;">
          <agm-map #AgmMap [latitude]="center.lat" [longitude]="center.lng" (mapClick)="updateCenter($event)">
            <agm-circle [latitude]="+center.lat" [longitude]="+center.lng" [radius]="+selectedRadius"
              [fillColor]="'blue'" [strokePosition]="'OUTSIDE'" [strokeWeight]="'3'" [strokeColor]="'blue'"
              [circleDraggable]="false" [editable]="false" (radiusChange)="radiusChanged()">
            </agm-circle>

            <agm-marker [latitude]="center.lat" [longitude]="center.lng" [animation]="'DROP'"
              [iconUrl]="'assets/img/blue-marker.png'">
            </agm-marker>
            <agm-marker *ngFor="let marker of events;let i = index" [latitude]="marker.latitude"
              [longitude]="marker.longitude" [animation]="marker.animation" (mouseOver)="markerOver(marker)"
              (mouseOut)="markerOut(marker)" (markerClick)="openWindow(marker.id)">
              <agm-info-window [isOpen]="isInfoWindowOpen(marker.id)" [latitude]="marker.latitude"
                [longitude]="marker.longitude">
                <div class="event-list-view">
                  <div class="event-list-view-contents-area">
                    <div class="event-list-view-title-and-price">
                      <div class="event-list-view-title-area">
                        {{marker.sales_location}}
                      </div>
                    </div>
                    <div class="event-list-view-footer-info">
                      <div class="date-time-area">
                        <span></span>
                        {{marker.event_start_datetime | date:'mediumDate'}}
                      </div>
                      <div class="location-area">
                        <span></span>
                        {{marker.distance}} Mile
                      </div>
                    </div>
                    <div class="event-list-view-description">
                      {{[marker.city, marker.prov_state, marker.country, marker.postal_code]}}
                    </div>
                    <div class="event-list-view-view-map">
                      <a [routerLink]="['/event-detail',marker.event_id]" target="_blank">
                        view event
                        <svg class="svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z">
                          </path>
                        </svg>
                      </a>
                    </div>
                    <!-- <div class="loc-directions"><a
                      href='https://maps.google.com/maps?saddr={{encodedUri(address)}}&amp;daddr={{event.latitude}},{{event.longitude}}'
                      target="_blank">Directions</a></div> -->
                  </div>
                </div>
              </agm-info-window>
            </agm-marker>


          </agm-map>
        </div>
        <div class="no-result" *ngIf="events?.length == 0">
          <div class="bh-sl-noresults-title">No results</div><br>
          <div class="bh-sl-noresults-desc">
            No Event locations were found with the given criteria.
            Please modify your selections or input.
          </div>
        </div>
        <div class="found-results {{resultViewType === 'GridView' ? 'grid-results' : 'list-results'}}" id="{{event.id}}"
          (click)="openWindow(event.id)" *ngFor="let event of events;let i = index" #{{event.id}}>
          <!-- <pre>{{event | json}}</pre> -->
          <app-grid-view *ngIf="resultViewType === 'GridView'" title="{{event.sales_location}}"
            distance="{{event.distance}}" mapLink="{{event.event_id}}" price=""
            eventDate="{{event.event_start_datetime | date:'mediumDate'}}" eventImage="">
          </app-grid-view>
          <app-list-view id="{{event.id}}" *ngIf="resultViewType === 'ListView'" title="{{event.sales_location}}"
            distance="{{event.distance}}" mapLink="{{event.event_id}}" price=""
            eventDate="{{event.event_start_datetime | date:'mediumDate'}}" eventImage=""
            eventDetails="{{[event.city, event.prov_state, event.country, event.postal_code]}}">
          </app-list-view>
        </div>
      </div>
    </div>



    

  </div>


</div>